import React from 'react';
import { EntityLayout } from '@backstage/plugin-catalog';
import { EntityAdrContent } from '@backstage-community/plugin-adr';
import PluginsUsed from '../PluginsUsed';
import { MermaidRenderer } from './MermaidRenderer';
import {
  createMDCommentRemoverDecorator,
  createRewriteRelativeLinksWithRecordAwareDecorator,
  createRewriteRelativeEmbedsWithRecordAwareDecorator,
  createFrontMatterFormatterDecorator,
} from './ADRDecorators';

/**
 * Creates the ADR route. Wraps EntityAdrContent with a MermaidRenderer and
 * uses several content decorators.
 */
export function createAdrRoute() {
  return (
    <EntityLayout.Route if={PluginsUsed.adr} path="/adrs" title="ADRs">
      <MermaidRenderer>
        <EntityAdrContent
          contentDecorators={[
            createMDCommentRemoverDecorator(),
            createRewriteRelativeLinksWithRecordAwareDecorator(),
            createRewriteRelativeEmbedsWithRecordAwareDecorator(),
            createFrontMatterFormatterDecorator(),
          ]}
        />
      </MermaidRenderer>
    </EntityLayout.Route>
  );
}
