import svgPanZoom from 'svg-pan-zoom';

export interface PanZoomOptions {
  enablePanZoom: boolean;
  fit: boolean;
  center: boolean;
  controlIconsEnabled: boolean;
}

/**
 * Initializes Pan/Zoom for a given SVG with the specified options.
 */
export function initializePanZoom(svg: SVGElement, options: PanZoomOptions) {
  return svgPanZoom(svg, {
    zoomEnabled: options.enablePanZoom,
    controlIconsEnabled: options.controlIconsEnabled,
    fit: options.fit,
    center: options.center,
    zoomScaleSensitivity: 0.3,
    minZoom: 0.1,
    maxZoom: 30,
  });
}

/**
 * Creates a fullscreen modal with a Pan/Zoom-enabled copy of the given SVG.
 */
export function showFullscreenModal(svg: SVGElement) {
  const modal = document.createElement('div');
  modal.style.position = 'fixed';
  modal.style.top = '0';
  modal.style.left = '0';
  modal.style.width = '100vw';
  modal.style.height = '100vh';
  modal.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
  modal.style.zIndex = '10000';
  modal.style.display = 'flex';
  modal.style.justifyContent = 'center';
  modal.style.alignItems = 'center';

  const closeButton = document.createElement('button');
  closeButton.textContent = 'CLOSE';
  closeButton.style.position = 'absolute';
  closeButton.style.top = '20px';
  closeButton.style.right = '20px';
  closeButton.style.width = '60px';
  closeButton.style.height = '30px';
  closeButton.style.border = 'none';
  closeButton.style.borderRadius = '4px';
  closeButton.style.backgroundColor = '#e0e0e0';
  closeButton.style.color = '#000';
  closeButton.style.fontSize = '14px';
  closeButton.style.textAlign = 'center';
  closeButton.style.lineHeight = '30px';
  closeButton.style.boxShadow = '0px 2px 5px rgba(0, 0, 0, 0.2)';
  closeButton.style.cursor = 'pointer';

  closeButton.addEventListener('click', () => {
    modal.remove();
  });

  const svgClone = svg.cloneNode(true) as SVGElement;
  svgClone.style.width = '90%';
  svgClone.style.height = '90%';
  svgClone.style.backgroundColor = '#fff';

  modal.appendChild(svgClone);
  modal.appendChild(closeButton);
  document.body.appendChild(modal);

  initializePanZoom(svgClone, {
    enablePanZoom: true,
    fit: true,
    center: true,
    controlIconsEnabled: true,
  });
}

/**
 * Adjusts a Mermaid SVG’s dimensions based on the container’s width/height attributes.
 */
export function adjustSvgDimensions(
  mermaidDiv: HTMLElement,
  svg: SVGElement,
  parentDiv: HTMLElement,
) {
  const widthAttr = parentDiv.getAttribute('data-width');
  const heightAttr = parentDiv.getAttribute('data-height');

  // If data-width or data-height is present, use them
  mermaidDiv.style.width = widthAttr ?? mermaidDiv.style.width;
  mermaidDiv.style.height = heightAttr ?? mermaidDiv.style.height;

  // Now size the SVG
  svg.style.width = `${mermaidDiv.offsetWidth}px`;
  svg.style.height = `${mermaidDiv.offsetHeight}px`;
  svg.style.overflow = 'auto';
}
