import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 25,
  },
  path: {
    fill: '#b5b5b5',
  },
});

const RadarIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      {/* Outer circle */}
      <circle cx="256" cy="256" r="240" stroke="#b5b5b5" strokeWidth="16" fill="none" />
      {/* Inner radar circles */}
      <circle cx="256" cy="256" r="180" stroke="#b5b5b5" strokeWidth="8" fill="none" />
      <circle cx="256" cy="256" r="120" stroke="#b5b5b5" strokeWidth="8" fill="none" />
      <circle cx="256" cy="256" r="60" stroke="#b5b5b5" strokeWidth="8" fill="none" />
      {/* Radar line */}
      <line
        x1="256"
        y1="256"
        x2="256"
        y2="16"
        stroke="#b5b5b5"
        strokeWidth="8"
        strokeLinecap="round"
      />
      {/* Radar sweep (arc) */}
      <path
        d="M256 256 L256 16 A240 240 0 0 1 496 256 Z"
        fill="rgba(181, 181, 181, 0.3)"
      />
    </svg>
  );
};

export default RadarIcon;
