import { Entity } from '@backstage/catalog-model';
import { isGithubActionsAvailable } from '@backstage-community/plugin-github-actions';
import { isAdrAvailable } from '@backstage-community/plugin-adr';

// A poll in the mobility cloud leadership google space preferred to hide unused components
// Let's define the entity callbacks to check if the component is used
const PluginsUsed = {
  cicd: isGithubActionsAvailable,

  api: (entity: Entity) => {
    const providesApis = Array.isArray(entity?.spec?.providesApis) && entity.spec.providesApis.length > 0;
    const consumesApis = Array.isArray(entity?.spec?.consumesApis) && entity.spec.consumesApis.length > 0;
    return providesApis || consumesApis;
  },

  ecs: (entity: Entity) => Boolean(entity.metadata.annotations?.['aws.amazon.com/amazon-ecs-service-arn']) 
    || Boolean(entity.metadata.annotations?.['aws.amazon.com/amazon-ecs-service-tags']),

  dependencies: (entity: Entity) => Array.isArray(entity?.spec?.dependsOn) 
    && entity.spec.dependsOn.some(dep => typeof dep === 'string' && (dep.startsWith('resource:') || dep.startsWith('component:'))),

  techdocs: (entity: Entity) => Boolean(entity.metadata.annotations?.['backstage.io/techdocs-ref']),

  allure: (entity: Entity) => Boolean(entity.metadata.annotations?.['qameta.io/allure-project']),

  adr: (entity: Entity) => isAdrAvailable(entity),
};

export default PluginsUsed;
