import { AdrContentDecorator } from '@backstage-community/plugin-adr';
import { parseMadrWithFrontmatter } from '@backstage-community/plugin-adr-common';
import { overwriteBaseUrl } from './DecoratorHelpers';

/**
 * Decorator that rewrites links to .md files using record-aware base URL
 */
export function createRewriteRelativeLinksWithRecordAwareDecorator(): AdrContentDecorator {
  return ({ baseUrl, content }) => {
    const newBaseUrl = overwriteBaseUrl(baseUrl);
    const updatedContent = content.replace(
      /\[([^\[\]]*)\]\((?!https?:\/\/)(.*?)(\.md)\)/gim,
      `[$1](${newBaseUrl}/$2$3)`
    );
    return { content: updatedContent };
  };
}

/**
 * Decorator that rewrites relative image embeds with record-aware base URL
 */
export function createRewriteRelativeEmbedsWithRecordAwareDecorator(): AdrContentDecorator {
  return ({ baseUrl, content }) => {
    const newBaseUrl = overwriteBaseUrl(baseUrl);
    const updatedContent = content.replace(
      /!\[([^\[\]]*)\]\((?!https?:\/\/)(.*?)(\.png|\.jpg|\.jpeg|\.gif|\.webp)(.*)\)/gim,
      `![$1](${newBaseUrl}/$2$3$4)`
    );
    return { content: updatedContent };
  };
}

/**
 * Decorator that formats front matter (like date, status) into a table
 */
export function createFrontMatterFormatterDecorator(): AdrContentDecorator {
  return ({ content }) => {
    const parsedFrontmatter = parseMadrWithFrontmatter(content);
    const attrs = { ...parsedFrontmatter.attributes };

    if (attrs.date) {
      const date = new Date(attrs.date as string);
      if (!isNaN(date.getTime())) {
        attrs.date = date.toISOString().split('T')[0];
      }
    }

    if (attrs.status) {
      attrs.status =
        String(attrs.status).charAt(0).toUpperCase() + String(attrs.status).slice(1);
    }

    const title = attrs.title ? `## ${attrs.title}\n\n` : '';
    delete attrs.title;

    const capitalizeKey = (key: string) =>
      key
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    let table = '';
    if (Object.keys(attrs).length > 0) {
      const stripNewLines = (val: unknown) =>
        Array.isArray(val)
          ? val.map(String).join(', ')
          : String(val).replace(/\n/g, '<br/>');
      const row = (vals: string[]) => `|${vals.join('|')}|\n`;

      const capitalizedKeys = Object.keys(attrs).map(capitalizeKey);
      table += row(capitalizedKeys);
      table += row(capitalizedKeys.map(() => '---'));
      table += row(Object.values(attrs).map(stripNewLines)) + '\n';
    }

    return {
      content: title + table + parsedFrontmatter.content,
    };
  };
}

/**
 * Decorator that removes HTML comments from the content
 */
export function createMDCommentRemoverDecorator(): AdrContentDecorator {
  return ({ content }) => {
    const sanitizedContent = content.replace(/<!--[\s\S]*?-->/g, '').trim();
    return { content: sanitizedContent };
  };
}
