import React, { useEffect, useRef } from 'react';
import mermaid from 'mermaid';
import pako from 'pako';
import { initializePanZoom, adjustSvgDimensions } from './PanZoomUtils';

/**
 * A helper that serializes Mermaid syntax for mermaid.live
 */
function serializeForMermaidLive(mermaidSyntax: string): string {
  const mermaidLiveJson = {
    code: mermaidSyntax,
    autoSync: true,
    editorMode: 'code',
    panZoom: true,
    rough: false,
    updateDiagram: true,
  };
  const compressed = pako.deflate(JSON.stringify(mermaidLiveJson));
  return btoa(String.fromCharCode(...compressed));
}

/**
 * Creates buttons (e.g. "maximize" and "mermaid.live") on a Mermaid diagram
 */
function addButtons(
  mermaidDiv: HTMLElement,
  svg: SVGElement,
  dataMap: Record<string, string>,
  mermaidLiveUrl?: string
) {
  const createButton = (
    text: string,
    onClick: () => void,
    styles: Partial<CSSStyleDeclaration>
  ) => {
    const button = document.createElement('button');
    button.innerHTML = text;
    Object.assign(button.style, {
      position: 'absolute',
      right: '5px',
      width: '80px',
      height: '25px',
      border: 'none',
      borderRadius: '4px',
      backgroundColor: '#A9A9A9',
      color: '#FFF',
      fontSize: '12px',
      fontWeight: 'bold',
      textAlign: 'center',
      lineHeight: '25px',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
      cursor: 'pointer',
      textDecoration: 'none',
      opacity: '0', // Start as invisible
      transition: 'opacity 0.3s',
      ...styles,
    });
    button.addEventListener('click', onClick);
    return button;
  };

  // Check if we show the maximize button
  const enableMaximizeButton = dataMap['maximize-button'] === 'true';
  // Check if we show the mermaid.live button
  const enableMermaidLiveButton = dataMap['mermaid-live-button'] === 'true';

  let maximizeButton: HTMLButtonElement | null = null;
  if (enableMaximizeButton) {
    maximizeButton = createButton('[&nbsp;&nbsp;]', () => showFullscreenModal(svg), {
      top: '5px',
      width: '25px',
    });
  }

  let exportButton: HTMLButtonElement | null = null;
  if (enableMermaidLiveButton && mermaidLiveUrl) {
    exportButton = createButton('mermaid.live', () => {
      window.open(mermaidLiveUrl, '_blank');
    }, { top: '35px' });
  }

  mermaidDiv.style.position = 'relative';
  mermaidDiv.addEventListener('mouseenter', () => {
    if (maximizeButton) maximizeButton.style.opacity = '1';
    if (exportButton) exportButton.style.opacity = '1';
  });
  mermaidDiv.addEventListener('mouseleave', () => {
    if (maximizeButton) maximizeButton.style.opacity = '0';
    if (exportButton) exportButton.style.opacity = '0';
  });

  if (maximizeButton) {
    mermaidDiv.appendChild(maximizeButton);
  }
  if (exportButton) {
    mermaidDiv.appendChild(exportButton);
  }
}

/**
 * A function to show the fullscreen modal for a cloned SVG.
 * We inlined the showFullscreenModal from PanZoomUtils to avoid circular imports.
 * If you prefer, you can import from PanZoomUtils instead.
 */
function showFullscreenModal(svg: SVGElement) {
  const modal = document.createElement('div');
  modal.style.position = 'fixed';
  modal.style.top = '0';
  modal.style.left = '0';
  modal.style.width = '100vw';
  modal.style.height = '100vh';
  modal.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
  modal.style.zIndex = '10000';
  modal.style.display = 'flex';
  modal.style.justifyContent = 'center';
  modal.style.alignItems = 'center';

  const closeButton = document.createElement('button');
  closeButton.textContent = 'CLOSE';
  closeButton.style.position = 'absolute';
  closeButton.style.top = '20px';
  closeButton.style.right = '20px';
  closeButton.style.width = '60px';
  closeButton.style.height = '30px';
  closeButton.style.border = 'none';
  closeButton.style.borderRadius = '4px';
  closeButton.style.backgroundColor = '#e0e0e0';
  closeButton.style.color = '#000';
  closeButton.style.fontSize = '14px';
  closeButton.style.textAlign = 'center';
  closeButton.style.lineHeight = '30px';
  closeButton.style.boxShadow = '0px 2px 5px rgba(0, 0, 0, 0.2)';
  closeButton.style.cursor = 'pointer';

  closeButton.addEventListener('click', () => {
    modal.remove();
  });

  const svgClone = svg.cloneNode(true) as SVGElement;
  svgClone.style.width = '90%';
  svgClone.style.height = '90%';
  svgClone.style.backgroundColor = '#fff';

  modal.appendChild(svgClone);
  modal.appendChild(closeButton);
  document.body.appendChild(modal);

  initializePanZoom(svgClone, {
    enablePanZoom: true,
    fit: true,
    center: true,
    controlIconsEnabled: true,
  });
}

/**
 * A small helper that scans lines starting with %% data-*="value" in the Mermaid code.
 * This lets us gather “attributes” out of the code fence text.
 */
function parseMermaidDataAttrs(rawMermaid: string): {
  dataMap: Record<string, string>;
  strippedMermaid: string;
} {
  const dataMap: Record<string, string> = {};
  const lines = rawMermaid.split('\n');

  const regex = /^%%\s+data-([a-zA-Z0-9_-]+)="([^"]*)"/;

  const filteredLines = lines.filter(line => {
    const match = line.match(regex);
    if (match) {
      const attrName = match[1];     // e.g. width
      const attrValue = match[2];    // e.g. 600px
      dataMap[attrName] = attrValue; // store in the object
      return false;                  // remove this line from the actual diagram text
    }
    return true; // keep line if no match
  });

  const strippedMermaid = filteredLines.join('\n');
  return { dataMap, strippedMermaid };
}

/**
 * Once the <div class="mermaid"> is created, we handle:
 * 1) Adjusting its width/height
 * 2) Optionally adding “mermaid.live” and “maximize” buttons
 * 3) Setting up pan/zoom
 */
function handleMermaidDivs(
  mermaidDiv: HTMLElement,
  dataMap: Record<string, string>,
  strippedMermaid: string
) {
    // should we do this?
  if (dataMap['pan-zoom'] === 'false') return;

  const svg = mermaidDiv.querySelector<SVGElement>('svg');
  if (!svg) return;

  // If user included width/height lines, apply them
  if (dataMap.width) {
    mermaidDiv.style.width = dataMap.width;
  }
  if (dataMap.height) {
    mermaidDiv.style.height = dataMap.height;
  }

  // Adjust the <svg> to fit the <div> we sized
  adjustSvgDimensions(mermaidDiv, svg, mermaidDiv);

  // Build the Mermaid Live URL if requested
  let mermaidLiveUrl: string | undefined;
  if (dataMap['mermaid-live-button'] === 'true') {
    const serializedSyntax = serializeForMermaidLive(strippedMermaid);
    mermaidLiveUrl = `https://mermaid.live/edit#pako:${serializedSyntax}`;
  }

  // Add top-right buttons
  addButtons(mermaidDiv, svg, dataMap, mermaidLiveUrl);

  // Finally, enable Pan/Zoom as requested
  initializePanZoom(svg, {
    enablePanZoom: dataMap['pan-zoom'] === 'true',
    fit: dataMap['pan-zoom-fit'] === 'true',
    center: dataMap['pan-zoom-center'] === 'true',
    controlIconsEnabled: dataMap['pan-zoom-control-icons'] === 'true',
  });
}

const processedMermaidDivs = new Set<HTMLElement>();
const parentOffsetClassNames = new Set<HTMLElement>();

/**
 * React component that watches for <pre><code class="language-text"> blocks,
 * tries to interpret them as Mermaid code, and transforms them into rendered diagrams.
 */
export function MermaidRenderer({ children }: { children: React.ReactNode }) {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const renderMermaidBlocks = () => {
      const codeBlocks = containerRef.current!.querySelectorAll('pre code.language-text');
      codeBlocks.forEach((block, index) => {
        const preElement = block.parentElement; // The <pre> container
        if (!preElement) return;

        // We check if we've already processed this element
        const offsetParentClassname = preElement.offsetParent?.className;
        if (offsetParentClassname && parentOffsetClassNames.has(preElement)) {
          return;
        }
        parentOffsetClassNames.add(preElement);

        // If we already see an <svg> inside this <pre>, skip it
        if (preElement.querySelector('svg')) {
          return;
        }

        // Gather code from child nodes
        let mermaidCode = '';
        block.childNodes.forEach(node => {
          mermaidCode += node.textContent || '';
        });

        // 1) Parse the code for data attributes
        const { dataMap, strippedMermaid } = parseMermaidDataAttrs(mermaidCode);

        // 2) Render the Mermaid diagram
        mermaid
          .render(`mermaid-svg-${index}`, strippedMermaid)
          .then(result => {
            // Replace <pre> with <div class="mermaid"><svg>...</svg></div>
            const mermaidDiv = document.createElement('div');
            mermaidDiv.classList.add('mermaid');
            mermaidDiv.innerHTML = result.svg;

            preElement.replaceWith(mermaidDiv);

            // Apply Pan/Zoom logic and buttons to the new .mermaid div
            if (!processedMermaidDivs.has(mermaidDiv)) {
              processedMermaidDivs.add(mermaidDiv);
              handleMermaidDivs(mermaidDiv, dataMap, strippedMermaid);
            }
          })
          .catch(err => {
            console.error('Mermaid rendering error:', err);
          });
      });
    };

    // Observe the container for new additions (async content load, etc.)
    const observer = new MutationObserver(() => {
      renderMermaidBlocks();
    });

    observer.observe(containerRef.current, { childList: true, subtree: true });

    // On initial mount, do one pass
    renderMermaidBlocks();

    return () => observer.disconnect();
  }, []);

  return <div ref={containerRef}>{children}</div>;
}
